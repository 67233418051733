var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"installationTabs"},[_c('ul',{staticClass:"nav nav-tabs"},[(
        !(
          _vm.authenticationHasRole('scope_customer') &&
          _vm.authenticationHasRole('zerolevel')
        )
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('operation') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s(_vm.$t("operations")))])])],1):_vm._e(),(_vm.envLoading)?_c('LoadingPlaceholder',{staticClass:"m-0",attrs:{"size":"small"}}):[(!_vm.authenticationHasRole('zerolevel') && _vm.showTrackerV4 == true)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('tracker') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Tracker V4")])])],1):_vm._e(),(!_vm.authenticationHasRole('zerolevel') && _vm.showTrackerV3 == true)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('trackerv3') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Tracker V3")])])],1):_vm._e(),(_vm.showEventMovie == true)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('eventmovie') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Event Movie")])])],1):_vm._e()],(
        _vm.showTab([
          'destinationos',
          'competition',
          'it service',
          'skiline online' ]) &&
          _vm.authenticationHasRole('scope_staff') &&
          (_vm.authenticationHasRole('admin') ||
          _vm.authenticationHasRole('firstlevel') ||
          _vm.authenticationHasRole('secondlevel'))
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('administration') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Administration")])])],1):_vm._e(),(
        !(
          _vm.authenticationHasRole('scope_customer') &&
          _vm.authenticationHasRole('zerolevel')
        )
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('freshdesk') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Tickets / Issues")])])],1):_vm._e(),(
        _vm.showTerminalSales ||
          _vm.showTerminalStatus ||
          (_vm.showTab(['terminal', 'mediastreamer']) &&
          _vm.authenticationHasRole('scope_customer') &&
          (_vm.authenticationHasRole('secondlevel') ||
          _vm.authenticationHasRole('zerolevel')))
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('terminal-status') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s(_vm.$t("status")))])])],1):_vm._e(),(_vm.showTab(['terminal']) && _vm.authenticationHasRole('scope_staff'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('terminal-tool') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Terminal")])])],1):_vm._e(),(_vm.showTerminalSales)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('terminalSales') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s(_vm.$t("terminalForm.salesTransactions")))])])],1):_vm._e(),(
        _vm.showTab([
          'skimovie',
          'bikemove',
          'photopoint',
          'phototrap',
          'photostart',
          'speedcheck',
          'time measurement',
          'eventmovie' ])
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('session') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s(_vm.$t("session")))])])],1):_vm._e(),(
        !(
          _vm.authenticationHasRole('scope_customer') &&
          _vm.authenticationHasRole('zerolevel')
        )
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('detail') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s(_vm.$t("details")))])])],1):_vm._e(),(
        !_vm.authenticationHasRole('zerolevel') &&
          !_vm.authenticationHasRole('firstlevel') &&
          !_vm.authenticationHasRole('firstlevel_connector') &&
          _vm.showTab(['skimovie', 'eventmovie'])
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('resources') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Resources")])])],1):_vm._e(),_c('li',{staticClass:"nav-item"},[(
          !_vm.showTab([
            'altimeter base service',
            'core service',
            'destinationos',
            'competition',
            'panocam',
            'emma',
            'it service',
            'skiline online' ]) || _vm.destinationOsDevice
        )?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('devices') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s(_vm.$t("devices")))])]):_vm._e()],1),_c('li',{staticClass:"nav-item"},[(!_vm.authenticationHasRole('zerolevel'))?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('installation-ips') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("IP Whitelist")])]):_vm._e()],1),(
        _vm.showTab([
          'photostart',
          'skimovie',
          'biketrack',
          'speedcheck',
          'phototrap',
          'photopoint' ])
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('reports') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s(_vm.$t("reports")))])])],1):_vm._e(),(_vm.showTab(['photopoint']))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('photopoint') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Photopoint")])])],1):_vm._e(),(!_vm.authenticationHasRole('zerolevel') && _vm.showTab(['phototrap']))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('phototrap') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Phototrap")])])],1):_vm._e(),(
        !_vm.authenticationHasRole('zerolevel') &&
          _vm.showTab(['phototrap', 'speedcheck', 'photostart'])
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('actioncam') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("ActionCam")])])],1):_vm._e(),(
        !_vm.authenticationHasRole('firstlevel') &&
          !_vm.authenticationHasRole('firstlevel_connector') &&
          !_vm.authenticationHasRole('zerolevel') &&
          _vm.showConverterConfigs
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('converterConfigs') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s("Converter"))])])],1):_vm._e(),(
        _vm.authenticationHasRole('scope_staff') &&
          (_vm.authenticationHasRole('secondlevel') ||
          _vm.authenticationHasRole('firstlevel') ||
          _vm.authenticationHasRole('admin')) &&
          _vm.showTab(['altimeter base service'])
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('converterSections') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s("Sections"))])])],1):_vm._e(),(
        _vm.authenticationHasRole('scope_staff') &&
          (_vm.authenticationHasRole('secondlevel') ||
          _vm.authenticationHasRole('firstlevel') ||
          _vm.authenticationHasRole('admin')) &&
          _vm.showTab(['altimeter base service'])
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('converterRoutingRules') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s(_vm.$t("routingRulesForms.routingRules")))])])],1):_vm._e(),(_vm.showTab(['panocam']))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('panocam-image') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s(_vm.$t("image")))])])],1):_vm._e(),(
        _vm.notificationSwitzerland && !_vm.authenticationHasRole('scope_customer')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('notificationSwitzerland') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Work Notification")])])],1):_vm._e(),(
        !_vm.authenticationHasRole('zerolevel') &&
          _vm.showTab(['photostart', 'speedcheck', 'phototrap', 'photopoint'])
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('svg-template') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("SVG Template")])])],1):_vm._e(),(
        _vm.showTab([
          'destinationos',
          'competition',
          'emma',
          'it service',
          'skiline online' ])
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('software-components') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Software Components")])])],1):_vm._e(),(
        _vm.showTab(['destinationos']) &&
          (_vm.authenticationHasRole('scope_staff') ||
          (_vm.authenticationHasRole('scope_customer') &&
          (_vm.authenticationHasRole('admin') ||
          _vm.authenticationHasRole('secondlevel') ||
          _vm.authenticationHasRole('firstlevel'))))
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('order-error-logs') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Order Error Log")])])],1):_vm._e(),(
        _vm.authenticationHasRole('scope_staff') ||
          (_vm.authenticationHasRole('scope_customer') &&
          (_vm.authenticationHasRole('admin') ||
          _vm.authenticationHasRole('secondlevel') ||
          _vm.authenticationHasRole('firstlevel')))
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('destination-os-emma-dashboard') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Dashboard")])])],1):_vm._e(),(_vm.showTab(['destinationos', 'emma']))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('sla') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("SLA")])])],1):_vm._e(),(
        _vm.authenticationHasRole('scope_staff') ||
          _vm.authenticationHasRole('scope_customer')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('installation-relations') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s("Relations"))])])],1):_vm._e(),(
        _vm.authenticationHasRole('scope_staff') && _vm.authenticationHasRole('admin')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('webhooks') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s("Webhooks"))])])],1):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('installation-contacts') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v(_vm._s(_vm.$t("installationDetailOperationView.contacts")))])])],1),(
        _vm.authenticationHasRole('crud_events') ||
          ((_vm.authenticationHasRole('scope_staff') ||
          _vm.authenticationHasRole('scope_customer')) &&
          (_vm.authenticationHasRole('admin') ||
          _vm.authenticationHasRole('zerolevel') ||
          _vm.authenticationHasRole('firstlevel') ||
          _vm.authenticationHasRole('secondlevel')))
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('event') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Event")])])],1):_vm._e(),(
        (_vm.authenticationHasRole('scope_staff') ||
          _vm.authenticationHasRole('scope_customer')) &&
          (_vm.authenticationHasRole('admin') ||
          _vm.authenticationHasRole('firstlevel') ||
          _vm.authenticationHasRole('secondlevel'))
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: _vm.pathTo('changelog') },"active-class":"active","tag":"a"}},[_c('span',[_vm._v("Changelog")])])],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }